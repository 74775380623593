const urlUtils = {
    mapKey: {
        uc: "user_coin",
        is: "image_src",
        ss: "source_scheme",
        fn: "full_name",
        av: "mver",
        pf: "platform",
        pk: "product_kind",
        ps: "price", //card类型支付需要使用
        ak: "appKind",
        un: "username",
        fc: "follow_count",
        lc: "like_count",
        vc: "view_count",
        cc: "comment_count",
        uj: "user_info",
        tj: "feed_data",
        oi: "offer_id",
        ru: "redirect_url",
        pc: "product_count",
        smc: "subscription_month_period",
        swc: "subscription_week_period",
        sdc: "subscription_day_period",
        sv: "system_version",
        dm: "device_model",
        lg: "lang",
        cui: "customer_user_id",
        iv: "idfv",
        ia: "idfa",
        ae: "actual_effect",
        el: "email",
        // 枚举约定字段
        // 1、stripe支付成功返回: stripe_success_page
        // 2、stripe支付失败返回: stripe_cancel_page
        fk: "from_kind",
        sci: "stripe_client_id",
        coid: "create_order_id",
        tcid: "transfer_client_id",
    },
    transformObject(obj) {
        let newObj = JSON.parse(JSON.stringify(obj));
        for (let i in newObj) {
            for (let j in this.mapKey) {
                if (i == j) {
                    newObj[this.mapKey[j]] = newObj[i];
                    delete newObj[i];
                }
            }
        }
        return newObj;
    },
    getUrlData(urlData) {
        let url = urlData.substring(urlData.indexOf("?") + 1); //获取url中"?"符后的字串
        let theRequest = new Object();
        let strs = url.split("&");
        for (let i = 0; i < strs.length; i++) {
            theRequest[strs[i].split("=")[0]] = unescape(
                strs[i].substring(strs[i].indexOf("=") + 1)
            );
        }
        return this.transformObject(theRequest);
    },
    getUrlQuery(urlData) {
        return urlData.substring(urlData.indexOf("?"));
    },
    setUrlLocalStorage() {
        window.localStorage
            ? localStorage.setItem(
                "urlData",
                JSON.stringify({ url: window.location.href })
            )
            : null;
    },
    getUrlLocalStorage() {
        return window.localStorage
            ? JSON.parse(localStorage.getItem("urlData"))
            : null;
    },
    stringify(obj, encode) {
        let pushArr = [];
        if (Object.prototype.toString.call(obj) === "[object Object]") {
            for (const key in obj) {
                if (Object.prototype.toString.call(obj[key]) === "[object Object]") {
                    pushArr.push(`${key}=${encodeURIComponent(JSON.stringify(obj[key]))}`);
                } else if (key !== undefined) {
                    pushArr.push(`${key}=${encode ? encodeURIComponent(obj[key]) : obj[key]}`);
                }
            }
            return pushArr.join("&");
        } else {
            throw new Error("expect object type param");
        }
    }
}

export default urlUtils;
