import { rejectError } from "../fetchCommon";
import utils from "common/utils";
import Fetch from "common/fetch";
import serviceUtils from "../util";
import config from "common/config";
import { eco_event } from "../../log_event/eco_event";
import {
  getCommonParams,
  errorRecord,
  redirectAfterApprove,
  appendCheckoutButtonAndDesc
} from "./checkoutCommonMethod";

const createOrder = (urlData, url, client_id, plan_id) => {
  return new Promise((resolve, reject) => {
    const path = "/web/v3/order/create";
    const post_data = getCommonParams(urlData, client_id);
    plan_id && (post_data.plan_id = plan_id);
    //请求服务器:
    Fetch.req({
      method: "POST",
      baseURL: url ? url : config.baseUrl, //后台分配的request_url,在/init的时候返回的
      url: path,
      data: post_data,
      encode: true,
      decode: true,
      timeout: 10000,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        console.log("createOrder data", data);
        resolve(data.id);
      })
      .catch((error) => {
        rejectError(error, path);
        reject(error);
      })
      .finally(() => {
        utils.$animation.hide();
      });
  });
};

let retryTimes = 0;
const approveOrder = (urlData, url, client_id, order_id, data) => {
  return new Promise((resolve, reject) => {
    const path = "/web/v3/order/approve";
    const post_data = getCommonParams(urlData, client_id);
    order_id && (post_data.transaction_id = order_id);
    data && (post_data.receipt = JSON.stringify(data));
    //请求服务器:
    Fetch.req({
      method: "POST",
      baseURL: url ? url : config.baseUrl, //后台分配的request_url,在/init的时候返回的
      url: path,
      data: post_data,
      encode: true,
      timeout: 15000,
      decode: true,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((data) => {
        console.log("approveOrder data", data);
        resolve(data);
      })
      .catch(async (error) => {
        // 如果是网络问题并且没有retry过
        if (error && !error.fromServer && !retryTimes) {
          retryTimes++;
          try {
            const result = await approveOrder(
              urlData,
              url,
              client_id,
              order_id,
              data
            );
            resolve(result);
          } catch (e) {
            reject(error);
          }
        } else {
          rejectError(error, path);
          reject(error);
        }
      });
  });
};

/**
 * 加载js的函数
 * @param url
 */
export const loadJs = (url) =>
  new Promise((resolve, reject) => {
    let script = document.createElement("script");

    script.type = "text/javascript";
    //IE
    if (script.readyState) {
      script.onreadystatechange = () => {
        if (script.readyState == "loaded" || script.readyState == "complete") {
          script.onreadystatechange = null;
          resolve(`loaded ${url} success`);
        }
      };
    } else {
      //其他浏览器
      script.onload = () => {
        resolve(`loaded ${url} success`);
      };

      script.onerror = () => {
        reject(`loaded ${url} failed`);
      };
    }
    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  });

const load_pay_pal_checkout_sdk = async (urlData) => {
  try {
    const isSubs = utils.isSubsOffer(urlData);
    const baseURL = "https://www.paypal.com/sdk/js";
    const { transfer_client_id } = urlData;
    const path = "?client-id=" + transfer_client_id + (isSubs ? "&vault=true" : "");
    const fullURL = baseURL + path;
    await loadJs(fullURL);
  } catch (e) {
    return Promise.reject(e);
  }
}

/**
 * 执行paypal button的初始化钩子的方法
 * Do not pass both createSubscription and createOrder
 * 不能同时挂载 createSubscription | createOrder
 * @param urlData
 */
const pay_pal_sdk_hooks = (urlData) => {
  const isSubs = utils.isSubsOffer(urlData);
  paypal
    .Buttons({
      // 如果是订阅用createSubscription，否则createOrder
      [isSubs ? "createSubscription" : "createOrder"]: async function () {
        return urlData.create_order_id;
      },
      onApprove: function (data, actions) {
        utils.$animation.finishProcessingAni();
        const order_id = isSubs ? data.subscriptionID : data.orderID;
        return approveOrder(
          urlData,
          urlData.request_url,
          urlData.transfer_client_id,
          order_id,
          data
        )
          .then((data) => {
            redirectAfterApprove(data, urlData);
          })
          .catch(() => {
            utils.$animation.hide();
          });
      },
      onCancel: function (data) {
        // Show a cancel page, or return to cart
        utils.$toast.warn("User cancelled");
      },
      onError: function (err) {
        console.log("onError", err);
        // Show an error page here, when an error occurs
        const isObject = Object.prototype.toString.call(err) === "[object Object]";
        const isError = Object.prototype.toString.call(err) === "[object Error]";
        if (isError) {
          try {
            console.log("onError name", err.name, err.message);
            errorRecord(urlData.request_url, err.message);
            utils.$toast.error(err.message ? err.message : "Unknown error");
          } catch (e) {
            utils.$toast.error("Unknown error");
          }
        } else if (isObject) {
          utils.$toast.error(err.errorMessage ? err.errorMessage : "Unknown error");
          errorRecord(urlData.request_url, err);
        } else {
          utils.$toast.error(err);
          errorRecord(urlData.request_url, err);
        }
      },
    })
    .render("#paypal-button-container")
    .then(() => {
      utils.$animation.hide();
    });
};

export const transferCheckout = async (urlData) => {
  appendCheckoutButtonAndDesc(urlData);

  await load_pay_pal_checkout_sdk(urlData);

  pay_pal_sdk_hooks(urlData);
}

const appendCheckoutButton = (
  clientId,
  request_url,
  plan_id,
  actual_effect,
  urlData
) => {
  try {
    // 后台createOrder返回的order_id
    const isSubs = utils.isSubsOffer(urlData);
    // 获取到pp的client_id，动态插入js到页面中
    const src =
      "https://www.paypal.com/sdk/js?client-id=" +
      clientId +
      (isSubs ? "&vault=true" : "");
    serviceUtils.loadJs(src, () => {
      //添加显示的数量,title,描述，价格，数量和pp的容器div
      appendCheckoutButtonAndDesc(urlData);
      paypal
        .Buttons({
          // 如果是订阅用createSubscription，否则createOrder
          [isSubs ? "createSubscription" : "createOrder"]: async function (
            data,
            actions
          ) {
            // 通过请求后台，返回产生此次的order_id
            const result = await createOrder(
              urlData,
              request_url,
              clientId,
              plan_id
            );
            return result;
          },
          onApprove: function (data, actions) {
            // console.log("onApprove", data);
            // 购买成功后的回调，pp会自动关闭iframe或者弹出的window
            utils.$animation.finishProcessingAni();
            const order_id = isSubs ? data.subscriptionID : data.orderID;
            return approveOrder(
              urlData,
              request_url,
              clientId,
              order_id,
              data
            )
              .then((data) => {
                redirectAfterApprove(data, urlData);
              })
              .catch(() => {
                utils.$animation.hide();
              });
          },
          onCancel: function (data) {
            // Show a cancel page, or return to cart
            utils.$toast.warn("User cancelled");
          },
          onError: function (err) {
            console.log("onError", err);
            // Show an error page here, when an error occurs
            const isObject = Object.prototype.toString.call(err) === "[object Object]";
            const isError = Object.prototype.toString.call(err) === "[object Error]";
            if (isError) {
              try {
                console.log("onError name", err.name, err.message);
                errorRecord(request_url, err.message);
                utils.$toast.error(err.message ? err.message : "Unknown error");
              } catch (e) {
                utils.$toast.error("Unknown error");
              }
            } else if (isObject) {
              utils.$toast.error(err.errorMessage ? err.errorMessage : "Unknown error");
              errorRecord(request_url, err);
            } else {
              utils.$toast.error(err);
              errorRecord(request_url, err);
            }
          },
        })
        .render("#paypal-button-container")
        .then(() => {
          utils.$animation.hide();
        });
    });
  } catch (e) {
    rejectError({ errorCode: 2 }, "load-paypal-js");
  }
};

export { createOrder, approveOrder, appendCheckoutButton };
