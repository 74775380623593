const pathMap = {
  success: "wps?dj=",
  fail: "wpf?em="
};

export default {
  success(protocol, queryString) {
    let backUrl;
    if (
      protocol &&
      (protocol.includes("http://") || protocol.includes("https://"))
    ) {
      backUrl = `${protocol}/OrderDetail/${queryString}`;
    } else {
      backUrl = `${protocol}://${pathMap.success}${queryString}`;
    }
    window.location.href = backUrl;
    // console.log(`redirected success url is ${backUrl}`);
    console.log("redirected success url is", backUrl);
  },
  fail(protocol, err) {
    let backUrl;
    if (
      protocol &&
      (protocol.includes("http://") || protocol.includes("https://"))
    ) {
      backUrl = `${protocol}/OrderDetail/${err}&ec=404`;
    } else {
      backUrl = `${protocol}://${pathMap.fail}${err}&ec=404`;
    }
    window.location.href = backUrl;
    console.log(`redirected fail url is ${backUrl}`);
  },
  empty(protocol) {
    const backUrl = `${protocol}://`;
    window.location.href = backUrl;
    console.log(`redirected empty url is ${backUrl}`);
  }
};
